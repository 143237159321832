import React, { useState, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import {
  Checkbox,
  makeStyles,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Tooltip,
  CircularProgress,
  TextField,
  FormControlLabel,
  Typography,
  Box
} from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";
import * as XLSX from "xlsx";
import { blue, green } from "@material-ui/core/colors";
import { Forward, Facebook, History, Instagram, SaveAlt, Star, WhatsApp } from "@material-ui/icons";
import StarBorderIcon from '@material-ui/icons/StarBorder';

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { i18n } from "../../translate/i18n";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import useDashboard from "../../hooks/useDashboard";
import ShowTicketLogModal from "../../components/ShowTicketLogModal";
import { AuthContext } from "../../context/Auth/AuthContext";

import { UsersFilter } from "../../components/UsersFilter";
import { WhatsappsFilter } from "../../components/WhatsappsFilter";
import { StatusFilter } from "../../components/StatusFilter";
import { TagsFilter } from "../../components/TagsFilter";
import QueueSelectCustom from "../../components/QueueSelectCustom";
import moment from "moment";
import toastError from "../../errors/toastError";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    overflowY: "auto",
    ...theme.scrollbarStyles,
    borderRadius: "10px",
  },
  searchField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
  },
  button: {
    borderRadius: "10px",
  },
  filterContainer: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  titleContainer: {
    margin: theme.spacing(1, 0),
  },
}));

const Reports = () => {
  const classes = useStyles();
  const history = useHistory();
  const { getReport } = useDashboard();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Defina o tamanho da página

  const [searchParam, setSearchParam] = useState("");
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);

  // const [tagIds, setTagIds] = useState([]);
  const [queueIds, setQueueIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [options, setOptions] = useState([]);
  const [dateFrom, setDateFrom] = useState(moment("1", "D").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [onlyRated, setOnlyRated] = useState(false);
  const [totalTickets, setTotalTickets] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const [ticketOpen, setTicketOpen] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts", {
            params: { searchParam },
          });
          setOptions(data.contacts);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam]);

  // const handleSelectedTags = (selecteds) => {
  //   const tags = selecteds.map((t) => t.id);
  //   setTagIds(tags);
  // };

  const exportarGridParaExcel = async () => {
    setLoading(true); // Define o estado de loading como true durante o carregamento

    try {
      const data = await getReport({
        searchParam,
        contactId: selectedContactId,
        whatsappId: JSON.stringify(selectedWhatsapp),
        // tags: JSON.stringify(tagIds),
        users: JSON.stringify(userIds),
        queueIds: JSON.stringify(queueIds),
        status: JSON.stringify(selectedStatus),
        // tags: tagIds,
        dateFrom,
        dateTo,
        page: 1, // Passa o número da página para a API
        pageSize: 9999999, // Passa o tamanho da página para a API
        onlyRated: onlyRated ? "true" : "false"
      });


      const ticketsData = data.tickets.map(ticket => {
        const createdAtString = ticket.createdAt;
        const closedAtString = ticket.closedAt || "";


        const parseDateTime = (dateTimeString) => {
          if (!dateTimeString) return { date: "", time: "" };
          const [date, time] = dateTimeString.split(" ");
          return { date, time };
        };

        const { date: dataCriacao, time: horaCriacao } = parseDateTime(createdAtString);
        const { date: dataFechamento, time: horaFechamento } = parseDateTime(closedAtString);

        return {
          id: ticket.id,
          Conexão: ticket.whatsappName,
          Contato: ticket.contactName,
          Usuário: ticket.userName,
          Fila: ticket.queueName,
          Status: ticket.status,
          ÚltimaMensagem: ticket.lastMessage,
          DataAbertura: dataCriacao,
          HoraAbertura: horaCriacao,
          DataFechamento: dataFechamento,
          HoraFechamento: horaFechamento,
          TempoDeAtendimento: ticket.supportTime,
          nps: ticket.NPS,
        };
      });

      console.log(ticketsData);
      const ws = XLSX.utils.json_to_sheet(ticketsData);
      const wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, 'RelatorioDeAtendimentos');
      XLSX.writeFile(wb, 'relatorio-de-atendimentos.xlsx');


      setPageNumber(pageNumber);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }

  };

  const handleFilter = async (pageNumber) => {
    setLoading(true); // Define o estado de loading como true durante o carregamento
    console.log(onlyRated)
    try {
      const data = await getReport({
        searchParam,
        contactId: selectedContactId,
        whatsappId: JSON.stringify(selectedWhatsapp),
        // tags: JSON.stringify(tagIds),
        users: JSON.stringify(userIds),
        queueIds: JSON.stringify(queueIds),
        status: JSON.stringify(selectedStatus),
        // tags: tagIds,
        dateFrom,
        dateTo,
        page: pageNumber, // Passa o número da página para a API
        pageSize: pageSize, // Passa o tamanho da página para a API
        onlyRated: onlyRated ? "true" : "false"
      });

      setTotalTickets(data.totalTickets.total);

      // Verifica se há mais resultados para definir hasMore
      setHasMore(data.tickets.length === pageSize);

      setTickets(data.tickets); // Se for a primeira página, substitua os tickets

      setPageNumber(pageNumber); // Atualiza o estado da página atual
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false); // Define o estado de loading como false após o carregamento
    }
  }

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id);
    setUserIds(users);
  };

  const handleSelectedWhatsapps = (selecteds) => {
    const whatsapp = selecteds.map((t) => t.id);
    setSelectedWhatsapp(whatsapp);
  };

  const handleSelectedStatus = (selecteds) => {
    const statusFilter = selecteds.map((t) => t.status);

    setSelectedStatus(statusFilter);
  };
  const IconChannel = (channel) => {
    switch (channel) {
      case "facebook":
        return <Facebook style={{ color: "#3b5998", verticalAlign: "middle" }} />;
      case "instagram":
        return <Instagram style={{ color: "#e1306c", verticalAlign: "middle" }} />;
      case "whatsapp":
        return <WhatsApp style={{ color: "#25d366", verticalAlign: "middle" }} />
      default:
        return "error";
    }
  };

  const renderOption = (option) => {
    if (option.number) {
      return <>
        {IconChannel(option.channel)}
        <Typography component="span" style={{ fontSize: 14, marginLeft: "10px", display: "inline-flex", alignItems: "center", lineHeight: "2" }}>
          {option.name} - {option.number}
        </Typography>
      </>
    } else {
      return `${i18n.t("newTicketModal.add")} ${option.name}`;
    }
  };

  const handleSelectOption = (e, newValue) => {
    setSelectedContactId(newValue.id);
    setSearchParam("");
  };

  const renderOptionLabel = option => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${option.name}`;
    }
  };
  const filter = createFilterOptions({
    trim: true,
  });

  const createAddContactOption = (filterOptions, params) => {
    const filtered = filter(filterOptions, params);
    if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
      filtered.push({
        name: `${params.inputValue}`,
      });
    }
    return filtered;
  };
  const renderContactAutocomplete = () => {
    return (
      <Grid xs={12} item>
        <Autocomplete
          fullWidth
          options={options}
          loading={loading}
          clearOnBlur
          autoHighlight
          freeSolo
          size="small"
          clearOnEscape
          getOptionLabel={renderOptionLabel}
          renderOption={renderOption}
          filterOptions={createAddContactOption}
          onChange={(e, newValue) => handleSelectOption(e, newValue)}
          renderInput={params => (
            <TextField
              {...params}
              label={i18n.t("newTicketModal.fieldLabel")}
              variant="outlined"
              autoFocus
              size="small"
              onChange={e => setSearchParam(e.target.value)}
              // onKeyPress={(e, newValue) => handleSelectOption(e, newValue)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
    )
  }

  return (
    <MainContainer>
      {openTicketMessageDialog && (
        <ShowTicketLogModal
          isOpen={openTicketMessageDialog}
          handleClose={() => setOpenTicketMessageDialog(false)}
          ticketId={ticketOpen.id}
        />
      )}
      <MainHeader>
        <div className={classes.titleContainer}>
          <Title>{i18n.t("reports.title")}</Title>
        </div>
      </MainHeader>
      <Paper variant="outlined" className={classes.mainPaper}>
        <MainHeaderButtonsWrapper>
          <Grid container spacing={1} className={classes.filterContainer}>
            <Grid item xs={12} md={3}>
              {renderContactAutocomplete()}
            </Grid>
            <Grid item xs={12} md={3}>
              <WhatsappsFilter onFiltered={handleSelectedWhatsapps} />
            </Grid>
            <Grid item xs={12} md={3}>
              <StatusFilter onFiltered={handleSelectedStatus} />
            </Grid>
            <Grid item xs={12} md={3}>
              <UsersFilter onFiltered={handleSelectedUsers} />
            </Grid>
            <Grid item xs={12} md={3} style={{ marginTop: -14 }}>
              <QueueSelectCustom selectedQueueIds={queueIds} onChange={values => setQueueIds(values)} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box style={{ padding: "0px 10px 10px" }}>
                <TextField
                  label="Data Inicial"
                  type="date"
                  value={dateFrom}
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(e) => setDateFrom(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box style={{ padding: "0px 10px 10px" }}>
                <TextField
                  label="Data Final"
                  type="date"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                <Grid item>
                  <Tooltip title={i18n.t("reports.buttons.onlyRated")} arrow>
                    <Box display="flex" flexDirection="column" alignItems="center" style={{ cursor: "pointer" }}>
                      <Checkbox
                        color="primary"
                        size="medium"
                        icon={<StarBorderIcon />}
                        checkedIcon={<Star />}
                        checked={onlyRated}
                        onChange={() => setOnlyRated(!onlyRated)}
                        style={{
                          marginTop: '-10px',
                          marginBottom: '-4px',
                        }}
                      />
                      <Typography
                        variant="caption"
                        align="center"
                        style={{
                          marginTop: '-4px',
                        }}
                      >
                        Avaliados
                      </Typography>
                    </Box>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Exportar para Excel" arrow>
                    <Box display="flex" flexDirection="column" alignItems="center" style={{ cursor: "pointer" }}>
                      <SaveAlt
                        onClick={exportarGridParaExcel}
                        aria-label="Exportar"
                      />
                      <Typography variant="caption" align="center">
                        Exportar
                      </Typography>
                    </Box>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleFilter(pageNumber)}
                      size="small"
                      style={{
                        marginTop: '4px', borderRadius: "12px"
                      }}
                    >
                      {i18n.t("reports.buttons.filter")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainHeaderButtonsWrapper>
        <Table size="small" id="grid-attendants" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("reports.table.id")}</TableCell>
              <TableCell align="left">{i18n.t("reports.table.whatsapp")}</TableCell>
              <TableCell align="left">{i18n.t("reports.table.contact")}</TableCell>
              <TableCell align="left">{i18n.t("reports.table.user")}</TableCell>
              <TableCell align="left">{i18n.t("reports.table.queue")}</TableCell>
              <TableCell align="center">{i18n.t("reports.table.status")}</TableCell>
              <TableCell align="left">{i18n.t("reports.table.lastMessage")}</TableCell>
              <TableCell align="center">{i18n.t("reports.table.dateOpen")}</TableCell>
              <TableCell align="center">{i18n.t("reports.table.dateClose")}</TableCell>
              <TableCell align="center">{i18n.t("reports.table.supportTime")}</TableCell>
              <TableCell align="center">{i18n.t("reports.table.NPS")}</TableCell>
              <TableCell align="center">{i18n.t("reports.table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {tickets && tickets.length > 0 ? (
                tickets.map((ticket) => (
                  <TableRow key={ticket.id}>
                    <TableCell align="center">{ticket.id}</TableCell>
                    <TableCell align="left">{ticket?.whatsappName}</TableCell>
                    <TableCell align="left">
                      <Tooltip title={ticket?.contactName || ''} arrow>
                        <span className={classes.cellContent}>
                          {ticket?.contactName && ticket.contactName.length > 20
                            ? `${ticket.contactName.slice(0, 20)}...`
                            : ticket?.contactName || ''}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">{ticket?.userName}</TableCell>
                    <TableCell align="left">{ticket?.queueName}</TableCell>
                    <TableCell align="center">{ticket?.status}</TableCell>
                    <TableCell align="left">
                      <Tooltip title={ticket?.lastMessage || ''} arrow>
                        <span className={classes.cellContent}>
                          {ticket.lastMessage && ticket.lastMessage.length > 20
                            ? `${ticket.lastMessage.slice(0, 20)}...`
                            : ticket.lastMessage || ''}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{ticket?.createdAt}</TableCell>
                    <TableCell align="center">{ticket?.closedAt}</TableCell>
                    <TableCell align="center">{ticket?.supportTime}</TableCell>
                    <TableCell align="center">{ticket?.NPS}</TableCell>
                    <TableCell align="center">
                      <Typography
                        noWrap
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        <Tooltip title="Logs do Ticket">
                          <History
                            onClick={() => {
                              setOpenTicketMessageDialog(true);
                              setTicketOpen(ticket);
                            }}
                            fontSize="small"
                            style={{ color: blue[700], cursor: "pointer", marginLeft: 10, verticalAlign: "middle" }}
                          />
                        </Tooltip>
                        <Tooltip title="Acessar Ticket">
                          <Forward
                            onClick={() => { history.push(`/tickets/${ticket.uuid}`) }}
                            fontSize="small"
                            style={{ color: green[700], cursor: "pointer", marginLeft: 10, verticalAlign: "middle" }}
                          />
                        </Tooltip>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                !loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      {i18n.t("Aplique um filtro para gerar o relatório")}
                    </TableCell>
                  </TableRow>
                )
              )}
              {loading && <TableRowSkeleton avatar columns={12} />}
            </>
          </TableBody>
        </Table>
      </Paper>

      <div className={classes.paginationContainer}>
        <Pagination
          count={Math.ceil(totalTickets / pageSize)}
          page={pageNumber}
          onChange={(event, value) => handleFilter(value)}
        />
        <FormControl
          margin="dense"
          variant="outlined"
          style={{ display: "flex", width: "150px", marginTop: "-5px" }}
        >
          <InputLabel>{i18n.t("tickets.search.ticketsPerPage")}</InputLabel>
          <Select
            labelId="dialog-select-prompt-label"
            id="dialog-select-prompt"
            name="pageSize"
            value={pageSize}
            onChange={(e) => {
              setPageSize(e.target.value)
            }}
            label={i18n.t("tickets.search.ticketsPerPage")}
            fullWidth
            MenuProps={{
              anchorOrigin: {
                vertical: "center",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "center",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>
      </div>
    </MainContainer >
  );
};

export default Reports;
